import { $postData, $getData } from "@/axios";

export default {
  async fetch_token({ commit }, payload) {
    let url = "/sys/login";
    let { data } = await $postData(url, { tempToken: payload });
    if (data.code == 200) {
      window.localStorage.setItem("zt", data.data.token);
      commit("UPDATE_DATA", { key: "token", value: data.data.token });
    }
    return data;
  },

  async fetch_userInfo({ dispatch, commit }) {
    let url = "/sys/user/info";
    let { data } = await $getData(url);
    if (data.code == 200) {
      commit("UPDATE_DATA", { key: "userInfo", value: data.data.user });
      dispatch('fetch_isMobileBind')
    }
    return data;
  },

  async fetch_isMobileBind({ commit }) {
    const url = '/sys/user/isMobileBind'
    const { data } = await $getData(url)
    if (data.code == 200) {
      commit('UPDATE_DATA', { key: 'isBoundPhoneNumber', value: !data.data})
    }
  },

  async fetch_navList({ commit }) {
    let url = "/sys/menu/nav";
    let { data } = await $getData(url);
    if (data.code == 200) {
      let permissions = data.data.permissions || [];
      let nav = data.data.urls || [];
      commit("UPDATE_DATA", { key: "permissions", value: permissions });
      commit("UPDATE_DATA", { key: "navList", value: nav }); // .concat(['/KingdeeClues'])
    }
    return data;
  },

  async fetch_totalCount({ commit }) {
    let url = "/api/work/panel/totalCount";
    let { data } = await $getData(url);
    return data;
  },

  //selectFlag = null 重复标签暂时不做
  async fetch_tags({ commit },selectFlag = null) {
    let url = "/api/common/cusTags";
    let { data } = await $getData(url,{ selectFlag:true } );
    let d = data.data || [];
    if (data.code === 200 && d.length) {
      d.map(i => (i.label = i.name) && (i.value = i.id));
    }
    commit("UPDATE_DATA", { key: "tags", value: d });
    return d;
  },

  async fetch_channel({ commit }, products) {
    let url = "api/clue/source/getAll";
    let { data } = await $getData(url, products);
    let d = data.data || [];
    if (data.code === 200 && d.length) {
      if (!products || products.launchFlag || products.isShowALL) {
        d.map(i => {
          if(i.nextLevel && i.nextLevel.length) i.nextLevel.unshift({ id: "", name: "全部" })
          });
      } else {
        d.map(i => (i.label = i.name) && (i.value = i.id) && (i.webShowFlagDis = !i.webShowFlag));
      }
    }
    commit("UPDATE_DATA", { key: "channel", value: d });
    return d;
  },

  // 四级级联查询接口
  async fetch_channel_four_level({ commit }, products) {
    // params 接口的入参
    const { params={} } = products;
    let url = "/api/source/channel/loop/list";
    let { data } = await $getData(url,params);

    let d = data.data || [];
    if (data.code === 200 && d.length) {
      if (!products || products.launchFlag || products.isShowALL) {
        // 只在第一级添加“全部”选项
        // d.unshift({ id: '', name: "全部" })
        d.forEach(topLevel => {
          topLevel.label = topLevel.name;
          topLevel.value = topLevel.id;
          topLevel.webShowFlagDis = !topLevel.webShowFlag;

          if (topLevel.nextLevel === null) {
            topLevel.nextLevel = []
          } else {
            topLevel.nextLevel.forEach(secondLevel => {
              if (secondLevel.nextLevel === null) {
                secondLevel.nextLevel = []
              } else {
                secondLevel.nextLevel.forEach(thirdLevel => {
                  if (thirdLevel.nextLevel === null) {
                    thirdLevel.nextLevel = []
                  } else {
                    thirdLevel.nextLevel.forEach(fourthLevel => {
                      if (fourthLevel.nextLevel === null) {
                        fourthLevel.nextLevel = []
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        d.forEach(topLevel => {
          topLevel.label = topLevel.name;
          topLevel.value = topLevel.id;
          topLevel.webShowFlagDis = !topLevel.webShowFlag;

          // 处理二级、三级和四级
          if (topLevel.nextLevel) {
            topLevel.nextLevel.forEach(secondLevel => {
              secondLevel.label = secondLevel.name;
              secondLevel.value = secondLevel.id;
              secondLevel.webShowFlagDis = !secondLevel.webShowFlag;

              if (secondLevel.nextLevel) {
                secondLevel.nextLevel.forEach(thirdLevel => {
                  thirdLevel.label = thirdLevel.name;
                  thirdLevel.value = thirdLevel.id;
                  thirdLevel.webShowFlagDis = !thirdLevel.webShowFlag;

                  if (thirdLevel.nextLevel) {
                    thirdLevel.nextLevel.forEach(fourthLevel => {
                      fourthLevel.label = fourthLevel.name;
                      fourthLevel.value = fourthLevel.id;
                      fourthLevel.webShowFlagDis = !fourthLevel.webShowFlag;
                    });
                  }
                });
              }
            });
          }
        });
      }

      // 提交更新后的数据到 Vuex store
      commit("UPDATE_DATA", { key: "channel", value: d });
      return d;
    }
  },
  async fetch_cusType({ commit }, products = {}) {
    let url = "api/common/cusType";
    let { data } = await $getData(url, products);
    let d = data.data || [];
    if (data.code === 200 && d.length) {
      if (products) {
        d.map(i => i.label = i.text);
      }
    }
    return d;
  },
  async fetch_agent({ commit }, products = {} ) {
    let url = "api/launch/agent/all";
    let { data } = await $getData(url, products);
    let d = data.data || [];
    if (data.code === 200 && d.length) {
      d.map(i => (i.label = i.text));
    }
    return d;
  }
};
